import * as React from 'react';
import { Link } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';

// markup
const NotFoundPage = () => {
  return (
    <Layout>
      <SEO
        title="404"
        description="Sorry, we couldn’t find what you were looking for."
        // keywords={[]}
        lang="en-gb"
      />
      <section
        style={{
          textAlign: 'center',
          paddingTop: '10rem',
          paddingBottom: '5rem',
        }}
      >
        <h1 style={{ textAlign: 'center' }}>Page not found</h1>
        <p style={{ textAlign: 'center' }}>
          Sorry we couldn’t find what you were looking for.
          <br />
          {process.env.NODE_ENV === 'development' ? (
            <>
              {/* <br />
            Try creating a page in <code style={codeStyles}>src/pages/</code>.
            <br /> */}
            </>
          ) : null}
          <br />
          <Link to="/">
            <button>Go home</button>
          </Link>
        </p>
      </section>
    </Layout>
  );
};

export default NotFoundPage;
